import { MenuItem, Typography } from "@mui/material";
import SelectField from "components/formFields/select-field.component";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import { useDeviceDetailsHook } from "features/device/device-details/hooks/device-details-hook";
import { DeviceFunction } from "features/device/models/device-function";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const DeviceSettingsFunction = (): JSX.Element => {
  const { t } = useTranslation("deviceDetails");

  const { currentViewingMode, currentDevice } = useDeviceDetailsHook();
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const registerOptions = {
    function: {
      required: t("info.requiredHelperText"),
    },
  };

  const options = [
    <MenuItem key={"present"} value={DeviceFunction.PresentAndAcceptAlarms}>
      {t("deviceFunctions.PresentAndAcceptAlarms")}
    </MenuItem>,
    <MenuItem key={"absent"} value={DeviceFunction.AbsentAndHandleAlarms}>
      {t("deviceFunctions.AbsentAndHandleAlarms")}
    </MenuItem>,
  ];

  return (
    <ViewingModeController
      viewingMode={currentViewingMode}
      name="function"
      control={control}
      rules={registerOptions.function}
      render={({ field }) => (
        <SelectField
          {...field}
          variant="outlined"
          label={t("NFC.functionLabel")}
          error={!!errors.function}
          helperText={errors.function?.message?.toString()}
          className="select-field"
          value={field.value ?? ""}
        >
          {options}
        </SelectField>
      )}
      display={
        <Typography variant="subtitle1">
          {`${t('NFC.functionLabel')}: ${currentDevice ? t(`deviceFunctions.${currentDevice.function}`) : t(`deviceFunctions.${DeviceFunction.PresentAndAcceptAlarms}`)}`}
        </Typography>
      }
    />
  );
};

export default DeviceSettingsFunction;
